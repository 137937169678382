<template>
  <div id="videoplayer_container">
    <!-- video intro -->
    <VideoBackgroundVue v-show="isOnstandByVideos" :playlist="options.standByVideos" />

    <!-- videos selector -->
    <VideoDouob v-for="item in options.videos" :key="item.id" :ref="item.id" :src="item.video.url"
      @ended="onVideoPlayerEnded" @progress="onProgressUpdate" />

    <!-- Fondo para que no moleste el video -->
    <div class="screenBackdrop" :class="{ active: showTutorial }">
    </div>
    
    <!-- Tutorial sin host control -->
    <div v-if="showTutorial && !$parent.hostControl" id="screenTutorial" class="is-flex is-flex-direction-column is-align-items-center p-5"
      style="background-image: url(/assets/videoselector/tutorial.png)">
      <div class="is-size-1 has-text-weight-bold my-5">{{ $t('exp_videoSelector.howToInteract') }}</div>
      <div class="tutorial-desc has-text-centered has-text-black-bis">
        {{ $t('exp_videoSelector.scanMeCta') }}
      </div>
    </div>
    <!-- Tutorial con host control -->
    <div v-if="showTutorial && $parent.hostControl" id="screenTutorial" class="has-background-white-ter is-flex is-flex-direction-column is-justify-content-center is-align-items-center p-5">
      <div class="tutorial-desc has-text-centered has-text-black-bis is-size-3">
        <div class="hourglass">
          <d-icon
            icon="FaHourglassHalf"
            size="is-xlarge"/>
        </div>
        <div class="is-size-1 mt-4 has-text-weight-bold">{{ $t('exp_videoSelector.waitTourGuideSelect') }}</div>
        <div class="is-size-3 px-5">{{ $t('exp_videoSelector.waitTourGuideSelectDescription') }}</div>
      </div>
    </div>

    <div v-if="!isOnstandByVideos" id="bottomInfo" :class="{ hide: hideMetadata }">
      <div id="videoPosition" v-show="!isOnstandByVideos">
        <div class="has-background-primary" :style="{ width: `${videoPosition * 100}vw` }"></div>
      </div>
      <div class="selectedVideoMetadata is-flex is-align-items-center">
        <div class="selected-title has-text-white-bis">{{ selected.title }} </div>
        <div class="chosen-by is-flex is-align-items-center has-text-white-bis is-uppercase" selectedTitle
          v-if="selectedUser">
          <div class="by has-text-weight-bold">
            {{ $t('exp_videoSelector.chosenBy') }} &nbsp;
          </div>
          <player-avatar :player="selectedUser" :scale="2"></player-avatar>
          <div class="username has-text-weight-medium">
            {{ selectedUser.username }}
          </div>
        </div>
      </div>
    </div>

    <qr-box />
    <user-list />
    <!--preload assets videos -->
    <preload-assets ref="PreloadAssets"/>

  </div>
</template>

<script>
import Vue from "vue";
import 'video.js/dist/video-js.css'
import "./screenVideoSelector.scss"

import VideoBackgroundVue from './VideoBackground.vue';
import VideoDouob from '../VideoDouob.vue';
import qrBox from './qrBox.vue';
import UserList from './userList.vue';
import PreloadAssets from "../PreloadAssets.vue";

export default {
  components: {
    VideoBackgroundVue,
    VideoDouob,
    qrBox,
    UserList,
    PreloadAssets,
  },
  data() {
    return {
      selectedUserId: null,
      selected: {},
      videoPosition: 0,
      showTutorial: false,
      hideMetadata: false,
      hideMetadataTimeout: null,
      isVideoEnded: false,
      selectedByHost: false,
    }
  },
  props: {
    options: {
      type: Object,
      required: true,
    },
  },
  methods: {
    SetVideo(data){
      const selected = this.options.videos.find(x => x.id == data.videoId)
      if (!selected) {
        console.log("❗️ Selected video not found")
      } else {
        console.log("New selected video", data, data.dbid)
        this.showTutorial = false
        this.selectedUserId = data.dbid;
        this.selected = selected;
        this.selectedByHost = data.isHost,
        this.hideMetadata = false;
        this.$sfxStop('waitingroommusic')
        this.$sfxStop('waitingroomstart')
        this.videoplayer.play()
        window.clearTimeout(this.hideMetadataTimeout)
        this.hideMetadataTimeout = setTimeout(() => {
          this.hideMetadata = true
        }, 5000);
      }
    },
    onProgressUpdate(progress) {
      this.videoPosition = progress
    },
    onVideoPlayerEnded() {
      console.log("video ended")
      // Next video
      const arrToLook = this.options.standByVideos; // con esto la playlist vuelve a los videos de intro
      let currElementIndex = arrToLook.indexOf(this.selected);
      const nextIndex = (currElementIndex + 1) % arrToLook.length;
      this.selected = arrToLook[nextIndex]
      this.selectedUserId = null;
      this.isVideoEnded = true;

      if (this.players.length > 0) this.showTutorial = true

      this.$socket.client.emit('roomPlayersMessage', { type: "unsetSlide", });
    },
  },
  computed: {
    videoplayer() {
      return this.$refs[this.selected.id][0];
    },
    standbyMode() {
      return this.players.length == 0;
    },
    selectedUser() {
      if (this.selectedUserId) {
        const player = this.players.find(x => x.dbid == this.selectedUserId)
        return player;
      }
      return false;
    },
    qrPosition() {
      if (this.isOnstandByVideos && this.standbyMode) return "center";
      else return "left";
    },
    qrSize() {
      if (this.isOnstandByVideos && this.standbyMode) return "large";
      else return "small";
    },
    isOnstandByVideos() {
      for (const _standByVideos of this.options.standByVideos) {
        if (this.selected == _standByVideos) return true;
      }
      return false;
    },
    players(){
      return this.$store.state.space.players;
    }
  }, 
  watch: {
    standbyMode(newVal) {
      if (newVal) {
        // Se fueron todos los jugadores
        this.$sfxStop('waitingroommusic');
        this.$sfxStop('waitingroomstart');
        this.showTutorial = false
        if (!this.isOnstandByVideos) { //si no esta en modo standBy
            if(this.options.config.stopVideosOnUserLeave ) { //Si el checkbox esta seleccionado
              console.log("!!! Stopping video")
              this.$refs[this.selected.id][0].stop()
              this.selected = this.options.standByVideos[0]
            }

        } 
      }
    },

    selected(selected, prevVideo) {
      console.log("Selected changed", selected.video.filename)
      if (prevVideo.id && this.$refs[prevVideo.id]) this.$refs[prevVideo.id][0].stop()
      
      if (selected.id && this.$refs[selected.id]) this.videoplayer.play()
    
      this.options.standByVideos.forEach(standByVideo => {
        if (selected.id == standByVideo.id && !this.standbyMode) this.$sfxPlay('waitingroommusic', { loop: true, fadein: true })
      })
      
      this.players.forEach((player) => {
        this.$socket.client.emit("messageToPlayer", {
          to: player.socketid,
          msg: "playingVideo",
          data: { videoId: selected.id, selectedByHost: this.selectedByHost },
          saveState: true, // guarda en servidor por si alguien entra despues le avisa
        })
      })
    }


  },
  sockets: {
    playerJoined(player){
      Vue.nextTick(() => {
        // Espero al proximo frame para que se actualice la lista de players
        console.log(this.selectedUserId)
        if(this.players.length == 1 && this.selectedUserId == null){
          const autoplay = this.options.videos.find(x => x.autoplay)
          if(autoplay){
            console.log("Primer jugador se unio. Reproduzco un video automaticamente")
            console.log(this.players[0])
            this.SetVideo({
              videoId: autoplay.id,
              dbid: this.players[0].dbid,
              isHost: false
            })
          }else{
            console.log("Primer jugador se unio pero no hay autoplay")
            this.showTutorial = true
            this.$sfxPlay('waitingroommusic', { loop: true, fadein: true })
            this.$sfxPlay('waitingroomstart')
          }
        }

        // Si hay un video elegido le aviso
        if(this.selectedUserId){
          this.$socket.client.emit("messageToPlayer", {
            to: player.socketid,
            msg: "playingVideo",
            data: { videoId: this.selected.id, selectedByHost: this.selectedByHost },
            saveState: true, // guarda en servidor por si alguien entra despues le avisa
          })
        }
      });
    },
    setVideo(data) {
      this.SetVideo(data)
    }
  },
  mounted() {
    this.selected = this.options.standByVideos[0]
    this.$sfxLoad(this.options.sounds,['waitingroommusic','waitingroomstart'])
    
    // preloaded videos
    const assets = [
      ...this.options.standByVideos.map(x => x.video.url),
      ...this.options.videos.map(x => x.video.url),
      "/assets/videoselector/tutorial.png"
    ]

    this.$refs["PreloadAssets"].StartPreloading(assets)
  }
};
</script>